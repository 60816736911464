import React from "react";
import { graphql } from 'gatsby';

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Documentation from '../template/pages/Documentation';

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          <Documentation {...props} />
      </Layout>
    </>
}

export default Page;
